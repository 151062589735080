import "./About.scss"

function About() {
  return (
    <>
      <section id="About" className="">
        <div id="about-header">
          <h1 id="banner">ABOUT</h1>
          <p className="goldman hey"> Hey, I'm Jeff</p>
          <center className="intro">
            <p>
              I live to learn, create, solve problems, and improve upon things. When I can find free time I enjoy playing guitar, dabbling in music recording and production, video games, sports, and hanging out with my family and friends. 
              <br/><br/> 
              <center style={{opacity: 0.5}}>
              Check out this simple three.js project of my 2021 GitHub Skyline. 
              </center>
            </p>
          </center>
        </div>
        <div id="iframe-container">
          <div id="left-boundary"/>
          <iframe id="skyline" src="https://my-skyline-2021.web.app/"></iframe>
          {/* <iframe src="http://localhost:5173//"></iframe> */}
          <div id="cover">
            <div id="top-boundary"></div>
            <div id="knockout"></div>
            <div id="bottom-boundary"></div>
          </div>
          <div id="center-holder"/>
          <div id="right-boundary"/>
        </div>
        <div id="about-footer"> 
          <br/>
          {/* <p style={{color: "orange"}}> Please note: All of these projects — except freckle. beauty — were created as part the UCF bootcamp, have gone unmaintained, and are falling apart. Especially Mars Report where NASA switched up their API. </p> */}
          <p style={{color: "orange"}}> As of 05/26/2024 I am back to caring about what this site looks like. Most of this is over 2 years old and I am in the process of re-evaluating and revamping. </p>
          {/* <p style={{color: "orange"}}> Also: frekcle. beauty is still being maintained but is recently undergoing migration and has been taken down. </p> */}
        </div>
      </section>
    </>
  )
}

export default About