import React, { useEffect, useState } from "react";
import resumeJSON from "../assets/json/resume.json";
import { customParser } from "../utils/helpers";
import "./Resume2.scss";

function Resume() {

  const [resumeStyle, setResumeStyle] = useState()

  useEffect(() => {
    setResumeStyle({
      opacity: "1",
      // fontSize: `${windowRatio}%`
    })
  }, []);

  return (
    <section
      id="Resume"
      style={resumeStyle}
    >
      <h1 id="banner">RESUME</h1>


      {/* <div className="intro">
      </div> */}

      <div className="hard-skills">
        <div className="heading goldman">
          <span>HARD</span> <span>SKILLS</span>
        </div>
        <div>
          {
            resumeJSON.HardSkills[0].split(", ").sort().map((val, index, array) => {
              return customParser(val, index, array.length)
            })
          }
        </div>
      </div>
      <div className="soft-skills">
        <div className="heading goldman">
        <span>SOFT</span> <span>SKILLS</span>
        </div>
        <div>
          {
            resumeJSON.SoftSkills[0].split(", ").sort().map((val, index, array) => {
              return customParser(val, index, array.length)
            })
          }
        </div>
      </div>

      <div className="work-experience">
        <div className="heading goldman"><span>WORK </span> <span>EXPERIENCE</span></div>
        {
          (resumeJSON.work).map((job, index) => (
            <>
            <table key={index}>
              <thead>
                <tr>
                  <th className="position">{job.position}</th>
                  <th className="location">{job.location}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="company">{
                    <a href={job.website} target="_blank">
                     {job.company}
                    </a>
                  }</td>
                  <td className="dates">{job.dates}</td>
                </tr>
                <tr>
                  <td className="description">{job.description}</td>
                </tr>
                <tr>
                  <td>
                    <ul>
                      {
                        (job.details).map((detail, index) => {
                          if (detail[0] === "x"){
                            if (detail[1] == "x") {
                                return (
                                  <ul>
                                      <ul>
                                          <li key={index}>{detail.slice(2)}</li>  
                                      </ul>
                                  </ul>
                              )
                            }
                            else {
                              return (
                                <ul>
                                  <li key={index}>{detail.slice(1)}</li>  
                                </ul>
                              )
                            }
                          }
                          else {
                            return (
                              <li key={index}>{detail}</li>
                            )
                          }
                        })
                      }
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="tech">{job.tech}</td>
                </tr>
              </tbody>
            </table>
              <spacer/>
            </>
          ))
        }
      </div>
      <div className="education">
        <div className="heading goldman"><span>EDUCATION</span></div>
        {
          (resumeJSON.education).map((education, index) => (
            <table key={index}>
              <thead>
                <tr>
                  <th>{education.institution}</th>
                  <th>{education.location}</th>
                </tr>
              </thead>
              <tbody>
                {(education.studies).map((study, index) => (
                  <tr key={index}>
                    <td>
                      <ul>
                        <li>
                          {study.program}
                        </li>
                      </ul>
                    </td>
                    <td>{study.dates}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))
        }
      </div>
    </section>
  )
}

export default Resume